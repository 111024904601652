@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans+SC:wght@300;400&display=swap');

.workImage {
    height: auto;
    width: 40%;
}

.projectCard {
    
    margin: 100px 0 100px 0;
    display: flex;
    justify-content: center;
    text-align: center;
   /*letter-spacing: -2.9px;*/
}

.left-box {
    text-align: left;
    z-index: 99;
    margin: 20px;
    width: 25%;
}

.cardContainer {
   display: flex;
}

.tech {
    font-size: 15px;
    letter-spacing: 2.1px;
    color: #7A8C82;
}

.info {
    background-color: #7A8C82;
    height: 50%;
    width: 125%;
    margin: 20px 0 20px 0;
    font-size: 18px;
    letter-spacing: 2.1px;
    text-align: center;
    align-content: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
}

.title {
    font-family: 'Alegreya Sans SC', sans-serif;
}

.smallTech {
    display:none;
}

.projectCard a:hover {
    color: #7A8C82;
  }

  @media (max-width: 1500px) {
    .workImage {
      width: 50%;
    }
    .smallTech {
        display: none;
    }
    .bigTech {
        display: block;
    }
  }
  
  @media (max-width: 1100px) {
    .workImage {
        width: 60%;
    }
    .smallTech {
        display: none;
    }
    .bigTech {
        display: block;
    }
  }
  
  @media (max-width: 900px) {
    .workImage {
        width: 90%;
        opacity: 0.5;
    }
    .left-box {
        text-align: center;
        z-index: 99;
        margin: 0px;
        width: 90%;
        position: absolute;
    }
    .info {
        background-color: transparent;
        width: auto;
    }
    .smallTech {
        display: block;
    }
    .bigTech {
        display: none;
    }
  }
