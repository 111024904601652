@import url(https://fonts.googleapis.com/css2?family=Caveat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cinzel&family=Special+Elite&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Allura&family=Playfair+Display+SC&family=Raleway&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Alegreya+Sans+SC:wght@300;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cairo&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Alegreya+Sans+SC:wght@300;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Alegreya+Sans+SC:wght@300;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Alegreya+Sans+SC:wght@300;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cairo&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* hide scrollbar but allow scrolling */
body {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
}

body::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.no-scroll{
  overflow: hidden;

}

.data-scroll-container {
  background-color: #191F2A;
}

.App {
  text-align: center;
}

.appBackground {
  background-color: #FDFBF2;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: 100px;
  color: #191F2A;
  font-family: 'Special Elite';
  width: 100%;
  position: relative;
  top: -71px;
}

.tint {
  z-index: 1;
  background: #E2A9A1 /*can be anything, of course*/
}

.backgroundTint {
  background-color: #E2A9A1; /* Tint color */
  background-blend-mode: multiply;
}

.navContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: 15px;
  margin: 50px;
}

.landingText {
  font-size: 100px;
}

#overlay {
  position: fixed; /* Sit on top of the page content */
  display: none;
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,.8); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  color: #FDFBF2;
}

.downArrow {
  width: 40px;
  height: 40px;
  color: #191F2A;
  position: absolute;
  bottom: 0px;
  margin-bottom: 30px;
  fill: #E2A9A1;
  stroke: #FDFBF2;
}

/*.downArrow:hover {
  fill: #7A8C82;
}*/

.bottomDivider {
  position: absolute;
  bottom: 0px;
}

.topDivider {
  position: absolute;
  top: -98px;
}

.section {
  margin: 20px;
  text-align: center;
}

.header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 9999;
  width: 100%;
  max-width: 100%;
  font-size: 12px;
}

.nav-right {
  float: right;
  display: block;
  position: relative;
  padding: 10px 28px;
}

.nav-left {
  float: left;
  display: block;
  position: relative;
  padding: 10px 28px;
}

h3 {
  white-space: nowrap;
  cursor: pointer;
  transition: opacity .3s;
  font-family: 'Roboto';
  color: #FDFBF2;
  font-weight: 500;
}

h3:hover {
  color: #7A8C82;
}

h1 {
  white-space: nowrap;
  cursor: pointer;
  transition: opacity .3s;
  font-family: 'Alegreya Sans SC', sans-serif;
  color: #FDFBF2;
  font-size: 20px;
  font-weight: 500;
}

h1:hover {
  color: #7A8C82;
}

.work {
  background-color: #191F2A;
  color: #FDFBF2;
  font-size: 100px;
  font-family: 'Roboto';
  letter-spacing: 2.1px;
  text-align: left;
  font-size: 40px;
  font-size: 1.5vw;
  margin-top: 4.5vw;
}

.footer {
  width: 100%;
  background-color: #191F2A;
  height: 100px;
  font-family: 'Roboto';
  color: #FDFBF2;
  font-weight: 500;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

h2 {
  white-space: nowrap;
  transition: opacity .3s;
  font-family: 'Alegreya Sans SC', sans-serif;
  color: #FDFBF2;
  font-size: 1.5vw;
  font-weight: 500;
}

@media (max-width: 1800px) {
  h2 {
    font-size: 2.5vw;
  }
  .work {
    font-size: 2.5vw;
  }
  .topDivider {
    top: -57px;
  }
}

@media (max-width: 1200px) {
  h2 {
    font-size: 3.5vw;
  }
  .work {
    font-size: 4.5vw;
  }
  .topDivider {
    top: -30px;
  }
}

@media (max-width: 800px) {
  h2 {
    font-size: 4.5vw;
  }
  .work {
    font-size: 4.5vw;
  }
  .topDivider {
    top: 0px;
  }
}

a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}

.loading {
  background-color: #191F2A;
}

/* .App[data-aos-duration='4000'] [data-aos],
  [data-aos][data-aos][data-aos-duration='4000'] {
    transition-duration: 4000ms;
  } */




/*FCF8F5
A1CBF1
CBDAEE
C0A3BB
4097DC*/

/*FDFBF2
7A8C82
E2A9A1
5B7674
191F2A*/
.workImage {
    height: auto;
    width: 40%;
}

.projectCard {
    
    margin: 100px 0 100px 0;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    text-align: center;
   /*letter-spacing: -2.9px;*/
}

.left-box {
    text-align: left;
    z-index: 99;
    margin: 20px;
    width: 25%;
}

.cardContainer {
   display: -webkit-flex;
   display: flex;
}

.tech {
    font-size: 15px;
    letter-spacing: 2.1px;
    color: #7A8C82;
}

.info {
    background-color: #7A8C82;
    height: 50%;
    width: 125%;
    margin: 20px 0 20px 0;
    font-size: 18px;
    letter-spacing: 2.1px;
    text-align: center;
    -webkit-align-content: center;
            align-content: center;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 10px;
}

.title {
    font-family: 'Alegreya Sans SC', sans-serif;
}

.smallTech {
    display:none;
}

.projectCard a:hover {
    color: #7A8C82;
  }

  @media (max-width: 1500px) {
    .workImage {
      width: 50%;
    }
    .smallTech {
        display: none;
    }
    .bigTech {
        display: block;
    }
  }
  
  @media (max-width: 1100px) {
    .workImage {
        width: 60%;
    }
    .smallTech {
        display: none;
    }
    .bigTech {
        display: block;
    }
  }
  
  @media (max-width: 900px) {
    .workImage {
        width: 90%;
        opacity: 0.5;
    }
    .left-box {
        text-align: center;
        z-index: 99;
        margin: 0px;
        width: 90%;
        position: absolute;
    }
    .info {
        background-color: transparent;
        width: auto;
    }
    .smallTech {
        display: block;
    }
    .bigTech {
        display: none;
    }
  }




.About {
    font-family: 'Roboto';
}

.AboutDesc {
    color: #fff;
    position: absolute;
    top: 20%;
    left: 10%;
    width: 50%;
    letter-spacing: 2.1px;
    text-align: left;
    font-size: 40px;
    font-size: 1.5vw;
}

@media screen and (max-width:600px) {

    .AboutDesc
    {
        font-size: 15px;
        font-size: 4vw;
    }
}

.icon {
    padding: 10px;
}

.icon:hover {
    stroke: #7A8C82;
}
