@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');  
@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans+SC:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cairo&display=swap');

.About {
    font-family: 'Roboto';
}

.AboutDesc {
    color: #fff;
    position: absolute;
    top: 20%;
    left: 10%;
    width: 50%;
    letter-spacing: 2.1px;
    text-align: left;
    font-size: 40px;
    font-size: 1.5vw;
}

@media screen and (max-width:600px) {

    .AboutDesc
    {
        font-size: 15px;
        font-size: 4vw;
    }
}

.icon {
    padding: 10px;
}

.icon:hover {
    stroke: #7A8C82;
}